import React from "react";
import { Grid } from "@material-ui/core";
import PageTitle from "../../../components/PageTitle/PageTitle";


export default function PujariApprovals(props) {
  return (
    <>
      <PageTitle title="Pujari Approvals" />
      <Grid container spacing={4}>
          pujari Approvals
      </Grid>
    </>
  );
}
