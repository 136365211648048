export const PUJA = {
    SET: 'PUJAS_SET',

}

export const PUJARI = {
    SET: 'PUJARIS_SET',
    CLEAR:'PUJARIS_CLEAR'
}

export const BOOKING={
    SET:'BOOKING_SET',
    CLEAR:'BOOKING_CLEAR',
    SET_PUJA:'SET_PUJA',
    SET_PUJARI: 'SET_PUJARI',
    SET_BOOKINGS:'SET_BOOKINGS'
}


