import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk'
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from './reducers';

const store = createStore(
  reducers,
  
  composeWithDevTools(applyMiddleware(ReduxThunk))
);

export default store;